/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-01-15T13:45:41.001Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '25.1.10000-1687065';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
